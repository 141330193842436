import { useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import InputMask from "react-input-mask";
import Success from "../../components/Success.component";
import Error from "../../components/Error.component";
import Partial from "../../components/Partial.component";
import Loader from "../../components/Loader.component";
import { useNavigate } from "react-router-dom";

const Quiz = ({ quiz, db, analytics, title }) => {
  const [question, setQuestion] = useState({});
  const [answer, setAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isBooleanQuestion = question.type === "boolean";

  const sendProgress = async (progress) => {
    const docRef = await addDoc(collection(db, "customers"), progress);
    console.log("Document written with ID: ", docRef.id);
    setIsLoading(false);
  };

  const updateQuestion = (progress, question, answer) => {
    !!question.update && question.update(progress, answer);
  };

  const convertAnswer = (event, answer) => {
    if (isBooleanQuestion) {
      return event.target.submitted === "yes" ? true : false;
    }

    return answer;
  };

  const getProgress = () => {
    let progress = JSON.parse(localStorage.getItem("progress")) || {};

    if (!progress.questions) {
      progress.questions = [];
    }

    return progress;
  };

  const saveProgress = (progress) => {
    localStorage.setItem("progress", JSON.stringify(progress));
  };

  const addQuestion = (progress, question) => {
    const index = progress.questions.findIndex((e) => e.id === question.id);
    if (index > -1) {
      progress.questions[index] = question;
    } else {
      progress.questions.push(question);
    }

    return progress;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    logEvent(analytics, `quiz_${question.id}_clicked`);

    let progress = getProgress();

    const answerValue = convertAnswer(event, answer);

    updateQuestion(progress, question, answerValue);

    const newQuestion = {
      id: question.id,
      text: question.text,
      answer: answerValue,
    };

    progress = addQuestion(progress, newQuestion);

    if (question.hasNext) {
      let nextQuestion = quiz.questions.find(
        (e) => e.id === question.next(progress)
      );

      progress.current = nextQuestion.id;
      setQuestion(nextQuestion);
      setAnswer("");

      if (nextQuestion.id < 1) {
        setIsLoading(true);
        sendProgress(progress);
      }
    } else {
      progress.current = question.id;
    }

    saveProgress(progress);
  };

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    const progress = JSON.parse(localStorage.getItem("progress"));

    let start = progress?.current || quiz.start;

    setQuestion(quiz.questions.find((e) => e.id === start));
  }, []);

  const handleReset = () => {
    localStorage.clear();
    // let nextQuestion = quiz.questions.find((e) => e.id === 1);
    // setQuestion(nextQuestion);
    // setAnswer("");
    navigate("/");
  };

  return (
    <div className="mx-auto px-20 pt-28 pb-16 text-center">
      {isLoading && <Loader />}

      {!isLoading && question.id === 0 && <Success handleReset={handleReset} />}

      {!isLoading && question.id === -1 && <Error handleReset={handleReset} />}

      {!isLoading && question.id === -2 && (
        <Partial handleReset={handleReset} />
      )}

      {!isLoading && question.id > 0 && (
        <div>
          <h1 className="mb-16 mx-auto font-display text-3xl font-light tracking-wide text-slate-900">
            {question.text}
          </h1>

          {isBooleanQuestion ? (
            <form onSubmit={handleSubmit} className="flex justify-between px-5">
              <button
                onClick={(event) => {
                  event.target.form.submitted = event.target.value;
                }}
                value="yes"
                type="submit"
                className="mt-16 group inline-flex items-center justify-center rounded-full py-3 px-6 text-lg font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-green-700 text-white hover:text-slate-100 hover:bg-green-600 active:bg-green-800 active:text-green-100 focus-visible:outline-green-700"
              >
                Sim
              </button>
              <button
                onClick={(event) => {
                  event.target.form.submitted = event.target.value;
                }}
                value="no"
                type="submit"
                className="mt-16 group inline-flex items-center justify-center rounded-full py-3 px-6 text-lg font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-red-700 text-white hover:text-slate-100 hover:bg-red-600 active:bg-red-800 active:text-red-100 focus-visible:outline-red-700"
              >
                Não
              </button>
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <InputMask
                mask={question.mask}
                onChange={(event) => setAnswer(event.target.value)}
                value={answer}
                type={question.type}
                name={question.name}
                placeholder={question.placeholder}
                pattern={question.pattern}
                autoComplete="off"
                required
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-primary-light focus:bg-white focus:outline-none focus:ring-primary-light sm:text-sm"
              ></InputMask>

              <button
                type="submit"
                className="mt-16 group inline-flex items-center justify-center rounded-full py-3 px-6 text-lg font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-primary text-white hover:text-slate-100 hover:bg-primary-light active:bg-primary-dark active:text-red-100 focus-visible:outline-primary"
              >
                Próximo
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default Quiz;
