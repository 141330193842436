import { Route, Routes } from "react-router-dom";
import Header from "./components/Header.component";
import Home from "./routes/home/Home.component";
import Quiz from "./routes/quiz/Quiz.component";

// import firebase from "firebase/app";
// import "firebase/firestore";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC4eAeisDJ-wSk9_p_59SmwbSKdlVU8D7Q",
  authDomain: "virtual-app-23.firebaseapp.com",
  projectId: "virtual-app-23",
  storageBucket: "virtual-app-23.appspot.com",
  messagingSenderId: "239486408337",
  appId: "1:239486408337:web:926966df90c33fe207d130",
  measurementId: "G-788BXHGHC0",
};

// firebase.initializeApp(firebaseConfig);
// const firestore = firebase.firestore();

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//analytics.setAnalyticsCollectionEnabled(true);
const db = getFirestore(app);

const Output = {
  Success: 0,
  Error: -1,
  Partial: -2,
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Header />}>
        <Route index element={<Home analytics={analytics} title="Home" />} />
        <Route
          path="quiz"
          element={
            <Quiz
              quiz={quiz}
              db={db}
              analytics={analytics}
              title="Questionario"
            />
          }
        />
      </Route>
    </Routes>
  );
}

const quiz = {
  start: 1,
  questions: [
    {
      id: 0,
      // text: "Parabéns, você tem direito! 🥳 Nossa equipe vai entrar em contato com você pelo whatsapp em breve.",
      text: "Parabéns, a princípio você TEM direito! 🥳 Nossa equipe vai entrar em contato com você pelo whatsapp em breve.",
      hasNext: false,
      update: function (progress, answer) {
        progress.result = true;
      },
      next: function (progress) {
        return 0;
      },
    },
    {
      id: -1,
      text: "Infelizmente você NÃO tem direito! 😔 Obrigado pela atenção.",
      hasNext: false,
      update: function (progress, answer) {
        progress.result = false;
      },
      next: function (progress) {
        return -1;
      },
    },
    {
      id: -2,
      text: "Existe a possibilidade de você TER DIREITO! 😀 Nossa equipe vai entrar em contato com você pelo whatsapp em breve.",
      hasNext: false,
      update: function (progress, answer) {
        progress.result = false;
      },
      next: function (progress) {
        return -2;
      },
    },
    {
      id: 1,
      text: "Informe seu nome completo",
      type: "text", // text, date, boolean, tel
      name: "name",
      hasNext: true,
      update: function (progress, answer) {
        progress.name = answer;
      },
      next: function (progress) {
        return 2;
      },
    },
    {
      id: 2,
      text: "Informe seu telefone completo (WhatsApp)",
      type: "tel",
      name: "phone",
      placeholder: "XX XXXXX-XXXX",
      pattern: "^[0-9]{2} [0-9]{5}-[0-9]{4}$",
      mask: "99 99999-9999",
      hasNext: true,
      update: function (progress, answer) {
        progress.phone = answer;
      },
      next: function (progress) {
        return 6;
      },
    },
    {
      id: 3,
      text: "Você está grávida?",
      type: "boolean",
      hasNext: true,
      update: function (progress, answer) {
        progress.isPregnant = answer;
      },
      next: function (progress) {
        let isPregnant = findAnswerToQuestion(progress, 3).answer;

        return isPregnant ? 4 : 5;
      },
    },
    {
      id: 4,
      text: "Previsão da data de nascimento do bebê?",
      type: "date",
      name: "will-born",
      hasNext: true,
      update: function (progress, answer) {
        progress.bornDate = answer;
      },
      next: function (progress) {
        return 7;
      },
    },
    {
      id: 5,
      text: "Data de nascimento do seu bebê?",
      type: "date",
      name: "born",
      hasNext: true,
      update: function (progress, answer) {
        progress.bornDate = answer;
      },
      next: function (progress) {
        return 13;
      },
    },
    {
      id: 6,
      text: "Já trabalhou de carteira assinada?",
      type: "boolean",
      hasNext: true,
      next: function (progress) {
        let hasWorked = findAnswerToQuestion(progress, 6).answer;

        return hasWorked ? 3 : -1;
      },
    },
    {
      id: 7,
      text: "Ainda está trabalhando de carteira assinada?",
      type: "boolean",
      hasNext: true,
      update: function (progress, answer) {
        progress.keepWorking = answer;
      },
      next: function (progress) {
        let keepWorking = findAnswerToQuestion(progress, 7).answer;

        return keepWorking ? -2 : 8;
      },
    },
    {
      id: 8,
      text: "Data da saída do último emprego de carteira assinada, antes de ter engravidado?",
      type: "date",
      name: "exit",
      hasNext: true,
      update: function (progress, answer) {
        progress.exitJobDate = answer;
      },
      next: function (progress) {
        let bornDate = toDate(findAnswerToQuestion(progress, 4).answer);
        let exitJobDate = toDate(findAnswerToQuestion(progress, 8).answer);

        bornDate.setFullYear(bornDate.getFullYear() - 1);
        bornDate.setMonth(bornDate.getMonth() - 3);

        if (bornDate < exitJobDate) {
          return 0;
        }

        return 9;
      },
    },
    {
      id: 9,
      text: "Pegou seguro desemprego?",
      type: "boolean",
      hasNext: true,
      update: function (progress, answer) {
        progress.hasInsurance = answer;
      },
      next: function (progress) {
        let hasInsurance = findAnswerToQuestion(progress, 9).answer;

        if (hasInsurance) {
          let bornDate = toDate(
            progress.questions.find((e) => e.id === 4 || e.id === 5).answer
          );

          let exitJobDate = toDate(
            progress.questions.find((e) => e.id === 8 || e.id === 13).answer
          );

          bornDate.setFullYear(bornDate.getFullYear() - 2);

          if (bornDate < exitJobDate) {
            return 0;
          } else {
            return -1;
          }
        }

        return 10;
      },
    },
    {
      id: 10,
      text: "Após a sua saída, você foi pessoalmente no SINE procurar emprego?",
      type: "boolean",
      hasNext: true,
      update: function (progress, answer) {
        progress.wentSine = answer;
      },
      next: function (progress) {
        let wentSine = findAnswerToQuestion(progress, 10).answer;

        if (wentSine) {
          return 11;
        }

        let isPregnant = findAnswerToQuestion(progress, 3).answer;

        if (isPregnant) {
          return 12;
        }

        return -1;
      },
    },
    {
      id: 11,
      text: "Qual foi a data que você foi no SINE?",
      type: "date",
      name: "sine",
      hasNext: true,
      update: function (progress, answer) {
        progress.wentSineDate = answer;
      },
      next: function (progress) {
        let exitJobDate = toDate(
          progress.questions.find((e) => e.id === 8 || e.id === 13).answer
        );
        let wentSineDate = toDate(findAnswerToQuestion(progress, 11).answer);

        exitJobDate.setFullYear(exitJobDate.getFullYear() + 1);
        exitJobDate.setMonth(exitJobDate.getMonth() + 3);

        if (wentSineDate <= exitJobDate) {
          return 0;
        }

        return -1;
      },
    },
    {
      id: 12,
      text: "A soma total do tempo trabalhado de carteira assinada é de pelo menos 10 meses?",
      type: "boolean",
      hasNext: true,
      update: function (progress, answer) {
        progress.isWorkedEnough = answer;
      },
      next: function (progress) {
        let isWorkedEnough = findAnswerToQuestion(progress, 12).answer;

        if (isWorkedEnough) {
          return -2;
        }

        return -1;
      },
    },
    {
      id: 13,
      text: "Data da saída do último emprego de carteira assinada, antes do bebê ter nascido?",
      type: "date",
      name: "exit",
      hasNext: true,
      update: function (progress, answer) {
        progress.exitJobDate = answer;
      },
      next: function (progress) {
        let bornDate = toDate(findAnswerToQuestion(progress, 5).answer);

        let exitJobDate = toDate(findAnswerToQuestion(progress, 13).answer);

        bornDate.setFullYear(bornDate.getFullYear() - 1);
        bornDate.setMonth(bornDate.getMonth() - 3);

        if (bornDate < exitJobDate) {
          return 0;
        }

        return 9;
      },
    },
  ],
};

function toDate(txt) {
  let parts = txt.split("-");
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

function findAnswerToQuestion(progress, id) {
  return progress.questions.find((e) => e.id === id);
}

export default App;
