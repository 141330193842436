import logo from "./../logo.jpeg";
import { Outlet, Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header>
        <div className="w-screen px-6 py-2 bg-primary">
          <nav className="flex justify-between">
            <div className="flex items-center">
              <Link to="/">
                <img
                  className="rounded-full h-10 w-auto"
                  src={logo}
                  alt="logo"
                ></img>
              </Link>
            </div>
          </nav>
        </div>
      </header>
      <main className="font-sans">
        <Outlet />
      </main>
    </>
  );
};

export default Header;
