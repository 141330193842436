const Success = ({ handleReset }) => {
  return (
    <>
      <div className="flex justify-center">
        <svg
          className="w-28 text-green-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>

      <div className="font-light">
        <p className="mt-10 mb-5">
          Parabéns, você <span className="font-bold">TEM direito!</span>
        </p>
        <p>
          Nossa equipe vai entrar em contato com você pelo WhatsApp em breve.
        </p>
        <button
          onClick={handleReset}
          className="mt-20 group inline-flex items-center justify-center rounded-full py-3 px-6 text-lg font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-primary text-white hover:text-slate-100 hover:bg-primary-light active:bg-primary-dark active:text-red-100 focus-visible:outline-primary"
        >
          Reiniciar
        </button>
      </div>
    </>
  );
};

export default Success;
