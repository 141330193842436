import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";

const Home = ({ analytics, title }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    logEvent(analytics, "start_quiz_clicked");
    navigate("/quiz");
  };

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="mx-auto px-16 pt-20 text-center max-w-lg">
      <h1 className="font-display text-5xl font-medium tracking-wide leading-tight	text-slate-900">
        Bem-vindo a{" "}
        <span className="relative whitespace-nowrap text-primary">
          <svg
            aria-hidden="true"
            viewBox="0 0 418 42"
            className="absolute top-2/3 left-0 h-[0.58em] w-full fill-primary-light/70"
            preserveAspectRatio="none"
          >
            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
          </svg>
          <span className="relative">Virtual</span>
        </span>{" "}
        assessoria
      </h1>

      <h2 className="mx-auto mt-20 text-lg tracking-wide text-slate-700 max-w-xs">
        Você pode ter direito de receber até{" "}
        <span className="text-primary font-bold underline decoration-2">
          R$ 5.150,00
        </span>{" "}
        <span className="">de</span>{" "}
        <span className="font-bold text-xl block">Auxílio Maternidade</span>
      </h2>

      <p className="mx-auto mt-20 text-base tracking-wide text-slate-500 max-w-xs">
        Clique no botão abaixo para verificar se você tem direito!
      </p>
      {/* <Link
        className="mt-10 inline-flex items-center justify-center rounded-full py-3 px-6 text-lg font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-primary text-white hover:text-slate-100 hover:bg-primary-light active:bg-primary-dark focus-visible:outline-primary"
        to="/quiz"
      >
        <span>Iniciar</span>
      </Link> */}

      <a
        onClick={handleClick}
        className="hover:cursor-pointer mt-10 inline-flex items-center justify-center rounded-full py-3 px-6 text-lg font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-primary text-white hover:text-slate-100 hover:bg-primary-light active:bg-primary-dark focus-visible:outline-primary"
      >
        <span>Iniciar</span>
      </a>
    </div>
  );
};

export default Home;
